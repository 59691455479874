import React from 'react'
import { Container, Nav, Navbar } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import img2 from "../Images/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import '../Styles/header.scss'
import { Company } from "../assets/Data";

function Header({ currentComponent, handleNavLinkClick }) {
  // const history = useHistory();
  const linkStyle = {
    fontSize: 16,
  };

  const CompanyData = Company[0];

  return (
    <>
      <header className="main d-flex justify-content-center">
        <div className="container row m-0 my-3 mb-3 w-100">
          {/* <div className="column col-md-3 col-12 p-0 d-flex justify-content-start">
            <img src={img2} className="logo" alt={CompanyData.name + "logo"} />
          </div> */}

          <div className="column order-0 col-md-4 col-12 justify-content-md-start justify-content-center">
              <a href='/' className='text-decoration-none no-underline'>
                <h1 className="pl-lg-3 headerH1 w-100">{CompanyData.name}</h1>
              </a>
              <span className="tagline w-100">{CompanyData.tagline}</span>
          </div>

          {/* <div className="column col-md-2 col-12 my-3 d-flex justify-content-center">
            <a href="/ProductList" className="btn button">
              Product List
            </a>
          </div> */}

          {/* <div className="column col-md-3 col-12  d-flex justify-content-center align-items-center">
            <Nav
              // className="d-flex linksPadding "
              navbarScroll
            >
              <NavDropdown
                title="Login As"
                style={{
                  marginRight: "20px",
                  fontSize: 16,
                }}
                align={"end"}
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item
                  style={DropdownLinkStyle}
                  onClick={() => history.push("/Customer")}
                >
                  Customer
                </NavDropdown.Item>
                <NavDropdown.Item
                  style={DropdownLinkStyle}
                  onClick={() => history.push("/Company")}
                >
                  Company
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                  style={DropdownLinkStyle}
                  onClick={() => history.push("/Salesman")}
                >
                  Salesman
                </NavDropdown.Item> 
              </NavDropdown>
            </Nav>
          </div> */}

          <div className="column order-md-1 order-2 col-md-6 col-12 d-flex justify-content-md-end justify-content-center align-items-center">
            <Navbar expand="lg" style={{ padding: 0 }}>
              <Container fluid className="navigation-bar container">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav
                    className="me-auto my-2 my-lg-0 container "
                    style={{
                      display: window.innerWidth < 1024 ? "flex" : "contents",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                    }}
                    navbarScroll
                  >
                    <Nav.Link
                      className={
                        (currentComponent === "Home" || currentComponent === "home"
                          ? "activeLink"
                          : "") + " menu-link "
                      }
                      style={linkStyle}
                      onClick={() => handleNavLinkClick("Home")}
                    >
                      Home
                    </Nav.Link>

                    <Nav.Link
                      className={
                        (currentComponent === "AboutUs" ? "activeLink" : "") +
                        " menu-link"
                      }
                      style={linkStyle}
                      onClick={() => handleNavLinkClick("AboutUs")}
                    >
                      About Us
                    </Nav.Link>

                    {/* <Nav.Link
                      className={
                        (currentComponent === "OurVision" ? "activeLink" : "") +
                        " menu-link linksPadding"
                      }
                      style={linkStyle}
                      onClick={() => handleNavLinkClick("OurVision")}
                    >
                      Our Vision
                    </Nav.Link>

                    <Nav.Link
                      className={
                        (currentComponent === "OurValues" ? "activeLink" : "") +
                        " menu-link linksPadding"
                      }
                      style={linkStyle}
                      onClick={() => handleNavLinkClick("OurValues")}
                    >
                      Our Values
                    </Nav.Link> */}

                    <Nav.Link
                      className={
                        (currentComponent === "ContactUs" ? "activeLink" : "") +
                        " menu-link"
                      }
                      style={linkStyle}
                      onClick={() => handleNavLinkClick("ContactUs")}
                    >
                      Contact Us
                    </Nav.Link>

                    {/* <Nav.Link
                      className=" menu-link"
                      onClick={() => history.push("/ProductList")}
                      style={linkStyle}
                    >
                      Products
                    </Nav.Link> */}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>

          <div className="column order-md-2 order-1 col-md-2 col-12 d-flex justify-content-md-end justify-content-center align-items-center">
            <a 
              href={`tel:${CompanyData.phone}`}
              className='text-decoration-none menu-link'
            >
              <FontAwesomeIcon icon={faPhone} /> {CompanyData.phone}
            </a>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header