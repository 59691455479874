import { useState } from "react";
import img1 from "../../../Images/slider/1.jpg";

// import { Carousel, Image } from 'react-bootstrap';
import { Carousel, Image } from "antd";

function Slider() {
  // const [selectedImage, setSelectedImage] = useState(0);
  const [allImages, setAllImages] = useState([img1]);

  // useEffect(() => {
  //     setInterval(() => {
  //         setSelectedImage(selectedImage => selectedImage > 1 ? 0 : selectedImage + 1)
  //     }, 2500);
  // }, [])

  return (
      <Carousel 
        autoplay 
        autoplaySpeed={2500}
      >
        {allImages.map((item, index) => (
          <Image
            src={item}
            key={index}
            width={"100%"}
            height="500px"
            alt={"ImagePlaceholder" + index}
          />
        ))}
        {/* </Carousel.Item> */}
      </Carousel>
  );
}
export default Slider;
