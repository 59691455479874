import React from 'react'
// import { Link } from "react-router-dom";
// import HeaderImage from '../../Images/HeaderImage'
// import { Company } from "../../assets/Data";
// import R_Logo from "../../components/company/R_Logo.png";
import '../../Styles/Dashboard/DashboardHeader.scss'
import TopHeader from '../TopHeader';

function DashboardHeader() {
  return (
    <div className="DashboardHeader w-100">
      <TopHeader />
    </div>
  );
}

export default DashboardHeader