import React from 'react'
import { Company } from "../../assets/Data";

function PrivacyPolicy() {
  return (
    <div className="container py-5">
      <h1 className="text-center w-100 mb-4">Privacy Policy</h1>
      <p>Shri Gajanan Medical Stores recognizes the importance of maintaining your privacy. 
        We value your privacy and appreciate your trust in us. 
        This Policy describes how we treat user information we collect on <a href='/' target='_blank'>gajananmedical.in</a> and other offline sources. 
        This Privacy Policy applies to current and former visitors to our website and to our online customers. 
        By visiting and/or using our website, you agree to this Privacy Policy.
      </p>

      <p>
        <a href='/' target='_blank'>gajananmedical.in</a> is a property of Shri Gajanan Medical Stores, a firm registered under the Companies Act, having its registered office at Opp Govt Hospital, Kacheri Road, Athani, Karnataka 591304
      </p>

      <h2 className="w-100">INFORMATION WE COLLECT</h2>

      <p>Contact information. We collect your Email, Mobile Number/Phone Number.</p>

      <h2 className="w-100">USE OF PERSONAL INFORMATION</h2>

      <p>We use information to contact you: We might use the information you provide to contact you for confirmation of a purchase on our website or for other promotional purposes.</p>

      <p>We use information to respond to your requests or questions. We might use your information to confirm your registration for any particular service requested on our website. </p>

      <p>We use information to improve our products and services. We might use your information to customize your experience with us. This could include displaying content based upon your preferences.</p>

      <p>We use information to look at site trends and customer interests. We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.</p>

      <p>We use information for security purposes. We may use information to protect our company, our customers, or our websites.</p>

      <p>We use information for marketing purposes. We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting.</p> 

      <p>We use information to send you transactional communications. We might send you emails or SMS about your account or a ticket purchase. </p>

      <p>We use information as otherwise permitted by law.</p>

      <h2 className="w-100">Email Opt-Out</h2>

      <p>You can opt out of receiving our marketing emails. To stop receiving our promotional emails, please email  <a href={`mailto:${Company[0].email}`}>{Company[0].email}.</a> </p>

      <h2 className="w-100">Updates to this policy</h2>

      <p>This Privacy Policy was last updated on 18/12/2023. From time to time we may change our privacy practices. Please check our site periodically for updates.</p>

      <h2 className="w-100">Jurisdiction</h2>

      <p>If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India. </p>

      <p>It is a long established fact that a reader will be distracted by the readable.</p>
      
      <p>Copyright © 2023 Shri Gajanan Medical Stores</p>
    </div>
  )
}

export default PrivacyPolicy