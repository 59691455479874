import React from "react";

const AboutUs = () => {
  console.log("About Us");
  return <div className="container py-5">
      <h1 className="text-center w-100">About Us</h1>
      <p className="text-center w-100">Established in 1986, Serving Athani town from last four decades with widest range of medicines, knowledgeable and courteous staff.</p>
      <p className="text-center w-100">Always available at your service.</p>
    </div>;
};

export default AboutUs;
