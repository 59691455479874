import React, { useEffect, useState } from "react";
import "../../../Styles/App.scss";
import ApiHandler from "../../../ApiHandler";
import GlobalVariable from "../../../GlobalVariable";
// import BearVerticalMarquee from 'bear-react-vertical-marquee';
// import 'bear-react-vertical-marquee/dist/index.css';

function MarqueeLComList() {
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    // fetch(GlobalVariable.ReliableSoftURL + "/api/POPSReports/AllCompanies/" + GlobalVariable.wcClient)
    //     .then((res) => res.json())
    //     .then((json) => setCompanyList(json));
    // fetch(" https://popsv3.relysoft.in/api/POPSReports/AllCompanies/" + wcClient)
    //     .then((res) => res.json().then((json) => setCompanyList(json)));
    ApiHandler.get({
      //url: localUrl + "api/OwnApp/GetComOrderList",
      url: "/api/POPSReports/AllCompanies/" + GlobalVariable.wcClient,
    })
      .then((result) => {
        console.log(result);
        setCompanyList(result);
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);
          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
      });
  }, []);

  return (
    <>
      <div
        className="overflow pl-lg-3 border-0"
        style={{
          height: window.innerHeight / 1.5,
        }}
      >
        {/*  eslint-disable-next-line jsx-a11y/no-distracting-elements */}
        {companyList && companyList.length > 0 && (
          <marquee direction="up" scrollamount="2" style={{ height: "100%" }}>
            {companyList.map((item) => (
              <li key={item.compCode}>{item.name}</li>
            ))}
          </marquee>
        )}
      </div>
    </>
  );
}
export default MarqueeLComList;
