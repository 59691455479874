const Company = [
  {
    name: "SHRI GAJANAN MEDICAL STORES",
    fullName: "SHRI GAJANAN MEDICAL STORES",
    tagline: "SGMS Healthcare Pvt Ltd",
    phone: "+91-9148598888",
    alternateNumber: [],
    email: "sgmshpl@gmail.com",
    website: "",
    // address:
    //   "OPP GOVT HOSPITAL, KACHERI ROAD, <br/>ATHANI, KARNATAKA <br/>591304",
    address: "CTS no 2384/1/B, opp Govt Hospital, <br/>Kacheri Road, Athani-591304 <br/>Karnataka, India",
    gstin: "29ABICS4883K1ZI",
  },
];

const BankDetails = [
  {
    label: "Bank Details",
    value: "",
  },
  {
    label: "Account Name",
    value: "",
  },
  {
    label: "A/C. No.",
    value: "",
  },
  {
    label: "IFSC Code:",
    value: "",
  },
];

export { Company, BankDetails };