import React from "react";
import Slider from "./Slider";
import MarqueeLComList from "./MarqueeLComList";
import { BackTop } from "antd";

const HomeComponent = () => {
  return (
    <div className="d-flex justify-content-center p-0">
      <div className="row p-0 w-100 d-flex justify-content-center">
          <Slider />
      </div>
    </div>
  );
};

export default HomeComponent;
