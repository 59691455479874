import React from "react";
import { useState } from "react";
// import LoaderTri from "../../components/Loader/LoaderTri";
// import { Image } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import { Company } from '../../assets/Data'

const ContactUs = () => {
  // const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="container py-5">
      <div className="row">

        <div className="col-12 mb-4">
          <h1 className="text-center w-100">Contact Us</h1>
        </div>

        <div className="col-md-6 col-sm-12">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3820.9879771609667!2d75.05609048885495!3d16.72745170000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc72a1d52929129%3A0x6832d9209cdb0535!2sShri%20Gajanan%20Medical%20Stores!5e0!3m2!1sen!2sin!4v1702017074981!5m2!1sen!2sin" 
            width="100%" 
            height="450"
            allowFullScreen
            loading="lazy"
            >
          </iframe>
        </div>

        <div className="col-md-6 col-sm-12 px-3">
          <div className="mt-5 mb-3 mx-4 contact-info">
            <p
              className="fw-bold m-0"
              dangerouslySetInnerHTML={{ __html: Company[0].address }}
            ></p>
          </div>

          <div className="contact mx-4">
            <ul className="info-list">
                {Company[0].alternateNumber !== '' && (
                  <li>
                    <p className="phone small-devices-text mr-1">
                      {Company[0].alternateNumber.join(", ")}
                    </p>
                  </li>
                )}

                <li>
                  <p className="phone small-devices-text mr-1">
                    <a href={`tel:${Company[0].phone}`}>
                      <FontAwesomeIcon icon={faPhone} /> {Company[0].phone}
                    </a>
                  </p>
                </li>

                <li className="email small-devices-text">
                  <p className="mr-1">
                    <a href={`tel:${Company[0].email}`}>
                      <FontAwesomeIcon icon={faEnvelope} /> {Company[0].email}
                    </a>
                  </p>
                </li>

                <li className="email small-devices-text">
                  <p className="mr-1">{Company[0].website}</p>
                </li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
