import React from "react";
// import iconPayStore from "../assets/badge-en.svg";
import { Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import '../Styles/footer.scss'
import { Company, BankDetails } from "../assets/Data";

function Footer({handleNavLinkClick}) {
  console.log(BankDetails);

  return (
    <>
      <footer
        className="footer pt-4"
        style={{
          bottom: 0,
          // position: "fixed",
          width: "100%",
        }}
      >
        <div>
          <div className="row m-0 footer-info d-flex justify-content-center">
            <div className="col-md-3 col-sm-12">
              <h4>Address</h4>
              <p className="footer-website-name small-devices-text d-block mb-0">{Company[0].name}</p>
              <p className="footer-website-tagline small-devices-text d-block mb-2">{Company[0].tagline}</p>
              <p
                className="address-section small-devices-text mr-1 mb-5 d-block w-100"
                dangerouslySetInnerHTML={{ __html: Company[0].address }}
              ></p>
            </div>

            <div className="col-md-3 col-sm-12">
              <h4>Contact</h4>
              <ul className="footer-list">
                {Company[0].alternateNumber != '' && (
                  <li>
                    <p className="phone small-devices-text mr-1">
                      {Company[0].alternateNumber.join(", ")}
                    </p>
                  </li>
                )}

                <li>
                  <p className="phone small-devices-text mr-1">
                    <FontAwesomeIcon icon={faPhone} />
                    <a href={`tel:${Company[0].phone}`}>
                      {Company[0].phone}
                    </a>
                  </p>
                </li>

                <li className="email small-devices-text">
                  <p className="mr-1">
                    <FontAwesomeIcon icon={faEnvelope} /> 
                    <a href={`tel:${Company[0].email}`}>
                      {Company[0].email}
                    </a>
                  </p>
                </li>

                <li className="email small-devices-text">
                  <p className="mr-1">{Company[0].website}</p>
                </li>
              </ul>
            </div>

            <div className="col-md-2 col-sm-12">
              <h4>Gstin</h4>

              <ul className="footer-list">
                {Company[0].gstin !== '' && (
                  <li className="small-devices-text">
                    <p className="mr-1">
                      {Company[0].gstin}
                    </p>
                  </li>
                )}
              </ul>
            </div>

            <div className="col-md-2 col-sm-12 d-sm-block">
              <h4>Links</h4>
              
              <ul className="footer-list">
                <li className="small-devices-text">
                  <Navbar expand="lg" style={{ padding: 0 }}>
                    <Nav.Link
                        onClick={() => handleNavLinkClick("PrivacyPolicy")}
                      >
                        Privacy Policy
                    </Nav.Link>
                  </Navbar>
                </li>
              </ul>
            </div>

            {/* <div className="col-md-4 col-sm-12">
              <ul className="footer-list">
                <li className="mb-4 small-devices-text sm-flex-center">
                  <table className="bank-details">
                    <tbody>
                      {BankDetails.map((item, index) => (
                        item.value != "" && (
                          <tr key={index}>
                            <td>{item.label}</td>
                            <td>{item.value}</td>
                          </tr>
                        )
                      ))}
                    </tbody>
                  </table>
                </li>
              </ul>
            </div> */}

            <section className="copyright">
              <p className="small-devices-text">
                Powered By:{" "}
                <a
                  href="http://www.relysoft.in/"
                  target="_blank"
                  rel="noreferrer"
                  className="powered-by text-sm-center"
                >
                  relysoft.in
                </a>
              </p>
            </section>

          </div>
        </div>
        
      </footer>
    </>
  );
}
export default Footer;
